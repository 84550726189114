import Link from "next/link";
import { useState } from "react";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import Loading from "../../components/Loading";
import { hostEventUrl, loginUrl, mediaUrl } from "../../config/config";
import AuthContext from "../../contexts/AuthContext";
import { successToaster } from "../../helpers/utils";
import Cookies from "js-cookie";
import { Auth } from "aws-amplify";
import { removeCognitoFromLocalStorage } from "../../common/Functions";
import Image from "next/future/image";
import dynamic from "next/dynamic";
import { useMediaQuery } from "react-responsive";
import { MenuToggle } from "../../components/Buttons/MenuToggle";
import { useCycle, motion } from "framer-motion";
import SideViewModal from "../DedicatedEventPage/GeneralComponents/SideViewModal";
// import Dashboard from '../../../public/img/profile-dropdown/Dashboard Layout.svg'
// import User from '../../../public/img/profile-dropdown/User.svg'
import Logout from "../../../public/img/profile-dropdown/Enter.svg";
//import ResourcesMenu from "./ResourcesMenu";
const SwitchApplications = dynamic(() =>
  import("../../components/NavigationBar/SwitchApplications.js")
);
// import ProfileCard from "../../components/NavigationBar/ProfileCard.js"
const ProfileCard = dynamic(() =>
  import("../../components/NavigationBar/ProfileCard.js")
);
const ResourcesMenu = dynamic(() => import("../../pages/Home/ResourcesMenu"));


const HomeNav = () => {
  const authContext = useContext(AuthContext);
  const router = useRouter();
  const [isOpen, setIsOpen] = useCycle(false, true);
  const [isDropdownVisible, setDropdownVisible] = useState(null);

  const handleLogout = async () => {
    try {
      let message = "Logged Out Successfully";
      successToaster(message);
      authContext.setAuth({ attributes: {} }, false);
      let cookies = Cookies.get();
      Object.keys(cookies).map((key) => {
        if (key.includes("CognitoIdentityServiceProvider")) Cookies.remove(key);
      });
      removeCognitoFromLocalStorage();
      Cookies.remove("authToken", { sameSite: "strict" });
      Auth.signOut();
      // router.replace('/', undefined, { shallow: false })
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleMouseEnter = (comp) => {
    setDropdownVisible(comp);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(null);
  };

  const handleMediaQueryChange = (matches) => {
    smallerScreen = matches;
    // matches will be true or false based on the value for the media query
  };
  let smallerScreen = useMediaQuery(
    { maxWidth: 991 },
    undefined,
    handleMediaQueryChange
  );

  let hideProfile = false;
  return (
    <>
      <nav className="d-flex app-header px-3 px-lg-4 px-lg-5 py-3 justify-content-between align-items-center align-items-lg-flex-start">
        <div style={{ zIndex: 100 }} className="ps-0">
          {/* <MenuToggle toggle={() => setIsOpen(prevState => !prevState)} isOpen={isOpen} /> */}
          <Link href="/">
            <Image
              src={"/img/logo.svg"}
              className="nav-logo d-inline-block link-text"
              alt="Konfhub"
              width={170}
              height={45}
              style={{ cursor: "pointer" }}
            />
          </Link>
        </div>
        {!smallerScreen && (
          <>
            <div
              style={{ zIndex: 100 }}
              className=" d-flex align-items-center justify-content-center"
            >
              <div className="app-menu d-none d-lg-flex d-xl-flex">
                <Link href="/events">
                  <p className="app-menu-item mx-lg-3 mx-md-1 py-2">Events</p>
                </Link>
                <Link href="/features">
                  <p className="app-menu-item mx-lg-3 mx-md-1 py-2">Features</p>
                </Link>
                <Link href="/usecases">
                  <p className="app-menu-item mx-lg-3 mx-md-1 py-2">
                    Use Cases
                  </p>
                </Link>
                <div
                  className="app-menu-item mx-lg-3 mx-md-1 pt-1 resources-margin"
                  onMouseEnter={() => handleMouseEnter("resources")}
                  onMouseLeave={handleMouseLeave}
                >
                  <span className="">
                    Resources
                    {isDropdownVisible === "resources" ? (
                      <i className="fas fa-angle-up px-2"></i>
                    ) : (
                      <i className="fas fa-angle-down px-2"></i>
                    )}
                  </span>
                  {isDropdownVisible === "resources" && <ResourcesMenu />}
                </div>
                <Link href="/pricing">
                  <p className="app-menu-item mx-lg-3 mx-md-1 py-2">Pricing</p>
                </Link>
              </div>
            </div>
            <div
              style={{ zIndex: 100 }}
              className="d-flex align-items-center justify-content-end"
            >
              <div className="app-menu d-none d-md-flex">
                <>
                  <a
                    href={`${hostEventUrl}/create/event`}
                    className="text-decoration-none mx-2"
                  >
                    <button className="btn btn-header btn-styles ">
                      Host Event
                    </button>
                  </a>
                  {authContext.signedIn === null ? (
                    <div className=" d-flex justify-content-center align-items-center  ms-3">
                      <div className="me-5"></div>
                      <Loading className={"m-auto"} color="#fb5850" />
                    </div>
                  ) : authContext.signedIn === true ? (
                    <div className=" d-flex justify-content-center align-items-center align-items-lg-baseline ms-3">
                      <div className="me-4 margin-small">
                        <SwitchApplications />
                      </div>
                      <ProfileCard
                        onLogout={(e) => handleLogout(e)}
                        hideProfile={hideProfile}
                      />
                    </div>
                  ) : (
                    <div className="d-flex header-menu-item">
                      <a
                        href={`${loginUrl}/login?redirect=Konfhub`}
                        className="text-decoration-none "
                      >
                        <button className="btn btn-login ">Log In</button>
                      </a>
                    </div>
                  )}
                </>
              </div>
            </div>
          </>
        )}
        {smallerScreen && authContext.signedIn != null && (
          <>
            <div
              style={{ zIndex: 100 }}
              className="d-flex align-items-center justify-content-end"
            >
              <div className="app-menu d-flex nav-mobile-menu">
                <>
                  {authContext.signedIn == true && (
                    <div className=" d-flex justify-content-center align-items-center ms-3">
                      <div className="me-4 margin-small">
                        <SwitchApplications />
                      </div>
                    </div>
                  )}
                </>
                {/* <Navigation /> */}
                <MenuToggle
                  toggle={() => setIsOpen((prevState) => !prevState)}
                  isOpen={isOpen}
                />

                <SideViewModal
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  isMenu={true}
                >
                  <div className="mt-5 px-3">
                    {authContext.signedIn === true && (
                      <div className="d-flex px-1 py-2 text-left switchEvent-pad pt-3">
                        <div className=" pr-0">
                          <img
                            className="profile-dropDown-img profile-container"
                            src={
                              authContext.user.attributes[
                                "custom:profilePicture"
                              ]
                            }
                            alt="Propfile P"
                          />
                        </div>
                        <div className=" details-container ms-2 pl-0 pr-0 mt-auto mb-auto">
                          <p
                            className="h5 profile-email mb-0"
                            style={{ color: "#572148" }}
                          >
                            {authContext?.user.attributes.name}
                          </p>
                          <p
                            className="profile-sub-text mb-0"
                            style={{ color: "#808080" }}
                          >
                            {authContext?.user.attributes.email}
                          </p>
                        </div>
                      </div>
                    )}
                    <a
                      href={`${hostEventUrl}/create/event`}
                      className="text-decoration-none w-100 d-block py-3"
                    >
                      <button className="btn btn-header btn-styles w-100 justify-content-center">
                        Host Event
                      </button>
                    </a>
                    {authContext.signedIn === false && (
                      <div className="d-flex header-menu-item">
                        <a
                          href={`${loginUrl}/login?redirect=Konfhub`}
                          className="text-decoration-none w-100 "
                        >
                          <button className="btn btn-login w-100 justify-content-center">
                            Log In
                          </button>
                        </a>
                      </div>
                    )}
                    <hr className="mt-2 nav-mob" />
                    <div className=" d-flex align-items-center">
                      <div className="app-menu">
                        <Link href="/events">
                          <p className="app-menu-item mx-lg-3 mx-md-1 pt-1">
                            Events
                          </p>
                        </Link>
                        <Link href="/features">
                          <p className="app-menu-item mx-lg-3 mx-md-1 pt-1">
                            Features
                          </p>
                        </Link>
                        <Link href="/usecases">
                          <p className="app-menu-item mx-lg-3 mx-md-1 pt-1">
                            Use Cases
                          </p>
                        </Link>
                        <Link href="/pricing">
                          <p className="app-menu-item mx-lg-3 mx-md-1 pt-1">
                            Pricing
                          </p>
                        </Link>
                        <div
                          className="app-menu-item mx-lg-3 mx-md-1 pt-1"
                          onMouseEnter={() => handleMouseEnter("resources")}
                          onMouseLeave={handleMouseLeave}
                        >
                          <span>
                            Resources
                            {isDropdownVisible === "resources" ? (
                              <i className="fas fa-angle-up px-2"></i>
                            ) : (
                              <i className="fas fa-angle-down px-2"></i>
                            )}
                          </span>
                          {isDropdownVisible === "resources" && (
                            <ResourcesMenu />
                          )}
                        </div>
                      </div>
                    </div>
                    {authContext.signedIn === true && (
                      <div
                        style={{
                          fontFamily: "Nunito",
                          fontWeight: "700",
                          letterSpacing: "0px",
                        }}
                      >
                        <hr className="mt-2 nav-mob" />
                        <a
                          href={`${hostEventUrl}`}
                          target="_blank"
                          className="text-decoration-none d-block pb-2"
                          style={{ color: "#572148" }}
                        >
                          <span className="">Dashboard</span>{" "}
                        </a>
                        <Link href="/profile">
                          <span
                            className="d-block py-2"
                            style={{ color: "#572148" }}
                          >
                            Manage Profile
                          </span>
                        </Link>
                        <hr className="mt-2 nav-mob" />
                        <span
                          className="me-2 py-2"
                          style={{
                            color: "#572148",
                            cursor: "pointer",
                            letterSpacing: "0px",
                          }}
                          onClick={(e) => handleLogout(e)}
                        >
                          Logout <img src={Logout} alt="logout icon" />
                        </span>
                      </div>
                    )}
                  </div>
                </SideViewModal>
              </div>
            </div>
          </>
        )}
      </nav>
      <style jsx>{`
        .resources-margin{
          margin-top : 4px !important;
        }
        .profile-container {
          height: 44px;
        }

        ${isOpen &&
        `body {
          overflow: hidden;
        }`}

        hr .nav-mob {
          border: #000;
          color: #cacaca !important;
        }

        .header-menu-item {
          color: #572148;
          font-size: 16px;
          text-decoration: none;
        }
        .app-header {
          background: #f6f9fc;
          width: 100%;
        }
        .nav-logo {
          height: 45px;
        }
        .link-text {
          cursor: pointer;
        }
        .btn-styles {
          font-family: Montserrat;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          height: 40px;
          letter-spacing: 0em;
          text-align: left;
        }
        .app-menu .app-menu-item {
          font-family: Nunito;
          font-size: 16px;
          font-weight: 700;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: left;
        }

        .header-menu-item {
          color: #572148;
          font-size: 16px;
          text-decoration: none;
        }
        .nav-logo {
          height: 45px !important;
        }
        .link-text {
          cursor: pointer;
        }
        .btn-styles {
          font-family: Montserrat;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          height: 40px;
          letter-spacing: 0em;
          text-align: left;
        }
        .app-menu .app-menu-item {
          font-family: Nunito;
          font-size: 16px;
          font-weight: 700;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: left;
        }

        .app-menu .app-menu-item:hover {
          text-decoration: underline;
        }

        .btn-login {
          display: flex;
          padding: 10px 25px;
          height: 40px;
          align-items: center;
          border: 1px solid #fb5850;
          border-radius: 6px;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          min-width: 100px;
          font-size: 16px;
          line-height: 20px;
          color: #fb5850;
        }

        .btn-login:hover {
          background: #fb5850;
          color: #572148;
        }

        .dropdown-menu.show {
          inset: unset !important;
        }
        .dropdown-menu > li {
          margin: 0px !important;
        }
        .app-menu .host-event {
          font-size: 16px;
          line-height: 24px;
          color: #fb5850;
          border: 2px solid #fb5850;
          text-decoration: none;
        }

        .app-menu .user {
          font-size: 20px;
        }

        .nav-buttons {
          color: #fff;
          text-decoration: none;
          z-index: 99;
        }

        .nav-buttons-active {
          color: #fb5850;
        }

        .dropdown-user {
          margin-top: 5px;
          display: flex !important;
          height: 100%;
          justify-content: center;
          align-items: center;
        }
        @media only screen and (max-width: 991px) {
          .btn-login {
            padding: 10px 22px;
          }
        }
        @media only screen and (max-width: 281px){
          .app-header{
            padding-right: .5rem !important;
            padding-left: .5rem !important;
          }
          .margin-small{
            margin-right: .5rem !important;
          }
        }
      `}</style>
    </>
  );
};

export default HomeNav;
